html,
body,
.sui-layout,
.sui-layout-body {
	font-family: "nexa";
	background-color: #dbe3e5;
}

.light {
	padding-top: 10px;
}
.minuscule{
	font-size: smaller;
}

.mnu {
	padding-right: 8px;
}

.schiffer {

	width: 100%;
	height: auto;
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #d7075d;
	font-family: "nexa";
	overflow-wrap: anywhere;
}

a {
	color: #006780;
	text-decoration: none;
	font-family: "nexa";
}

.bs-link-color {
	color: #016882;
}

a:active,
a:hover {
	color: #d6075c;
}

.sui-layout-body:after {
	background: rgb(228, 227, 242);
}

.sui-search-box__submit {
	background: none;
	font-weight: bold;
	font-family: "nexa";
}

.sui-search-box__submit:hover {
	background: none;
	border: none;
}

.sui-select__control {
	background-color: #d7075d;
}

.sui-layout-header {
	border-bottom: none !important;
}

.sui-layout-header {
	padding-top: 12px;
	padding-bottom: 0px;
	padding-right: 0px;
	padding-left: 0px;
	border-bottom: 1px solid #eeeeee;
}

.sui-layout-header .sui-search-box {
	border: none;
	font-family: "nexa";
	color: red;
}

.sui-layout-main {
	padding-top: 8px;
	padding-bottom: 8px;
	padding-right: 0px;
	padding-left: 0px;
}

.sui-layout-main-header {
	padding-top: 4px;
	padding-bottom: 8px;
	padding-right: 0px;
	padding-left: 0px;
}

.clr {
	padding-top: 12px;
	padding-left: 12px;
}

.sui-layout-sidebar {
	width: 27%;
	padding-top: 24px;
	padding-bottom: 8px;
	padding-right: 12px;
	padding-left: 0px;
}

.sui-layout-sidebar-toggle {
	border: 1px solid #d6075c;
}

.sui-layout-body:after {
	background-color: transparent;
}

.sui-result__details h5 svg {
	margin-right: 8px;
}

.airy {
	padding: 32px 24px;
	margin-bottom: 29px;
}

.airy .row .col-lg {
	padding-bottom: 24px;
}

.navbar-menu {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	margin-right: auto;
	background-color: transparent;
	box-shadow: none;
}

.navbar-item {
	align-items: center;
	flex-grow: 0;
	flex-shrink: 0;
	vertical-align: -webkit-baseline-middle;
}

[dir="rtl"] .navbar-start {
	margin-right: 0;
	margin-left: auto;
}

[dir="rtl"] .navbar-end {
	margin-left: 0;
	margin-right: auto;
}

img.card {
	height: 100%;
	border-radius: 0.25rem;
}

.card-image {
	height: 190px;
	overflow: hidden;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.sui-multi-checkbox-facet__option-count,
.sui-facet-view-more {
	color: #006780;
}

.sui-paging .rc-pagination-item a {
	color: #d6075c;
	font-size: x-large;
}

.sui-facet,
.sui-sorting,
.sui-select {
	font-family: "nexa";
}

.sui-sorting {
	display: contents
}

.sui-facet__title,
.sui-search-box__text-input {
	color: #d6075c !important;
}

sui-select__value-container {
	color: darkseagreen;
}

.sui-select__single-value {
	color: white !important;
	font-weight: bold;
}

.sui-search-box__text-input,
.sui-search-box__submit {
	border: none;
	font-size: large;
}

.sui-search-box__text-input:focus {
	border-color: #d6075c;
}

.sui-search-box__submit {
	background-color: #d6075c;
	color: white;
}

.sui-facet {
	margin-top: 8px !important;
	margin-left: 8px;
	margin-right: 8px;
}

.sui-layout-body__inner {
	padding: 0px 0px;
}

.cnt {
	margin-left: 20%;
	margin-right: 20%;
	margin-bottom: 12px;
}

.agyaLogo {
	width: 320px;
	min-height: 60px;
}


.lang-select {
	width: 33%;
	display: unset;
	display: inline-block;
	border-radius: 0px	;
}

#popover-basic {
	position: absolute;
	top: 20%;
	left: 70%;
	padding: 0px;
	display: inline-block;
}

.navbar {
	align-items: stretch;
	display: flex;
	position: relative;
	z-index: 30;
	background-color: white;
	border-bottom: #006780 2px solid;
	padding-inline: 2%;
}

.last {
	display: flex;
	gap: 10px;
	/* Adjust spacing between items */
}

/* Make the language select dropdown take minimal space */
.form-select.lang-select {
    padding: 5px;
    border-radius: 5px;
    border: 0px solid #ccc;
    width: auto; /* Ensure it only takes necessary space */
    min-width: 100px; /* Prevent it from being too small */
    max-width: 150px; /* Prevent it from stretching too much */
    text-align: center; /* Center the selected text */
}

/* Expand dropdown to the left when opened */
.form-select.lang-select:focus {
    width: 200px; /* Expand to the left */
    right: 0;
    z-index: 10; /* Ensure it appears on top of other elements */

	border-radius: 0px	;
}
